import { Coverage } from '@policyfly/protobuf'
import {
  Any,
  BoolValue,
  BytesValue,
  DoubleValue,
  FloatValue,
  Int32Value,
  Int64Value,
  StringValue,
  UInt32Value,
  UInt64Value,
} from '@policyfly/protobuf/google/protobuf'
import { isObject } from '@policyfly/utils/type'
import { ScalarType } from '@protobuf-ts/runtime'

import type { ReadonlyCoverage } from '@/stores/protobuf'
import type { PolicyState } from '@policyfly/protobuf'
import type { AnyObject } from '@policyfly/types/common'
import type { DeepReadonly } from 'vue'

export * from './changeList'
export * from './fieldInfo'
export * from './mappers'

interface MessageWithCoverages {
  coverages: Coverage[]
}

export interface CoverageDetail<T extends MessageWithCoverages | DeepReadonly<MessageWithCoverages> = DeepReadonly<MessageWithCoverages>> {
  /**
   * The coverage itself.
   */
  coverage: T extends MessageWithCoverages ? Coverage : ReadonlyCoverage
  /**
   * The path to the coverage from the root.
   */
  path: string
  /**
   * The {@link CoverageDetail} of the parent coverage if nested.
   * Can be recursed up until the root, where it will be `null`.
   */
  parent: CoverageDetail<T> | null
  /**
   * A list of children {@link CoverageDetail} of this coverage.
   * Each child will have a reverse relationship to this using `parent`.
   */
  children: CoverageDetail<T>[]
}
/**
 * Recurses through the provided message and finds all the {@link Coverage coverages} inside.
 * Returns the coverages and some meta details about them.
 */
export function extractAllCoverages<T extends MessageWithCoverages | DeepReadonly<MessageWithCoverages>> (
  message: T,
  prefix = '',
  parent: CoverageDetail<T> | null = null,
): CoverageDetail<T>[] {
  const coverageDetails: CoverageDetail<T>[] = []
  if (message.coverages) {
    message.coverages.forEach((c, index) => {
      const coverage = c as T & CoverageDetail<T>['coverage']
      const path = `${prefix}coverages.${index}`
      const detail: CoverageDetail<T> = {
        coverage,
        path,
        parent,
        children: [],
      }
      coverageDetails.push(detail)
      if (parent) parent.children.push(detail)
      const nestedCoverages = extractAllCoverages(coverage, `${path}.`, detail)
      coverageDetails.push(...nestedCoverages)
    })
  }
  return coverageDetails
}

/**
 * Extracts the value from an {@link Any} wrapper, removes the value from the wrapper and returns it.
 * If an invalid or impossible to unwrap value is provided will return `undefined`.
 */
export function extractAnyWrapper<T> (value?: Any): T | undefined {
  if (!value) return undefined
  try {
    const extracted = Any.toJson(value, {
      typeRegistry: [
        DoubleValue,
        FloatValue,
        Int64Value,
        UInt64Value,
        Int32Value,
        UInt32Value,
        BoolValue,
        StringValue,
        BytesValue,
      ],
    }) as { value: T }
    return extracted.value
  } catch {
    return undefined
  }
}

/**
 * Sets the initial starting state on a {@link PolicyState} when loading for Quote Review.
 *
 * This will do the following:
 * - Merge the first offer in `offered` up, whilst preserving the `offered` array
 * - Mark the first item in an array as `selected: true` if that flag exists
 *   - This will choose the first with `offered: true` if that also exists
 * - Set `disabled` and `wasEnabled` on all coverages based on the `enabled` flag
 *
 * @param policyState The {@link PolicyState} to update. Will be mutated.
 * @returns The mutated `policyState` for chaining.
 */
export function setQuoteReviewDefaults (policyState: PolicyState): PolicyState {
  if (!policyState.quotes?.quotes) return policyState
  for (const quote of policyState.quotes.quotes) {
    for (const coverage of quote.coverages) {
      recurseQuoteReviewState(coverage)
    }
  }
  return policyState
}
function recurseQuoteReviewState (obj: AnyObject): void {
  if ('offered' in obj && Array.isArray(obj.offered) && obj.offered.length) {
    // update all offers before merging up
    for (const o of obj.offered) {
      recurseQuoteReviewState(o)
    }
    const offered = obj.offered
    Object.assign(obj, offered[0], { offered })
  } else if (Coverage.is(obj)) {
    obj.wasEnabled = obj.enabled
  }
  for (const [k, v] of Object.entries(obj)) {
    // offered is already handled above
    if (k === 'offered') continue
    if (Array.isArray(v)) {
      let selectedChosen = false
      v.forEach((item) => {
        if (!isObject(item)) return
        if ('selected' in item) {
          if (!selectedChosen && (!('offered' in item) || item.offered === true)) {
            item.selected = true
            selectedChosen = true
          } else {
            item.selected = false
          }
        }
        recurseQuoteReviewState(item)
      })
    } else if (isObject(v)) {
      recurseQuoteReviewState(v)
    }
  }
}

/**
 * Converts the value to the correct scalar type.
 * `null` will be returned as is unless the scalar type is `ScalarType.BOOL`.
 */
export function convertScalar (value: unknown, scalarType: ScalarType): unknown {
  switch (scalarType) {
    case ScalarType.BOOL:
      return !!value
    case ScalarType.STRING:
      if (value !== null) return String(value)
      break
    default: // everything else should be number
      if (value !== null) return Number(value || 0)
  }
  return value
}
