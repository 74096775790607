import { GenericStatus } from '@policyfly/protobuf'

import type { ApplicationStatus } from '@policyfly/types/application'
import type { PolicyStatus } from '@policyfly/types/policy'

const genericStatusToPolicyStatusMap = new Map<GenericStatus, PolicyStatus>([
  [GenericStatus.CANCELLED, 'CANCELLED'],
  [GenericStatus.CANCELLED_WITH_ERROR, 'CANCELLED'],
  [GenericStatus.DECLINED, 'INACTIVE'],
  [GenericStatus.DRAFT, 'INACTIVE'],
  [GenericStatus.EXPIRED, 'EXPIRED'],
  [GenericStatus.IN_FORCE, 'ACTIVE'],
  [GenericStatus.QUALITY_CONTROL, 'INACTIVE'],
  [GenericStatus.QUOTED, 'INACTIVE'],
  [GenericStatus.REQUESTED, 'INACTIVE'],
  [GenericStatus.SUBMITTED, 'ACTIVE'],
  [GenericStatus.UNBOUND, 'INACTIVE'],
  [GenericStatus.READY_FOR_RENEWAL, 'ACTIVE'],
  [GenericStatus.RENEWAL_DECLINED, 'ACTIVE'],
  [GenericStatus.RENEWAL_OFFERED, 'ACTIVE'],
  [GenericStatus.RENEWING, 'ACTIVE'],
  [GenericStatus.RENEWED, 'ACTIVE'],
  [GenericStatus.UNKNOWN, 'INACTIVE'],
])
/**
 * Converts a {@link GenericStatus} to a {@link PolicyStatus}.
 */
export function genericStatusToPolicyStatus (status: GenericStatus): PolicyStatus {
  return genericStatusToPolicyStatusMap.get(status) ?? 'INACTIVE'
}

const policyStatusToGenericStatusMap = new Map<PolicyStatus, GenericStatus>([
  ['CANCELLED', GenericStatus.CANCELLED],
  ['INACTIVE', GenericStatus.DRAFT],
  ['EXPIRED', GenericStatus.EXPIRED],
  ['ACTIVE', GenericStatus.SUBMITTED],
])
/**
 * Converts a {@link PolicyStatus} to a {@link GenericStatus}.
 */
export function policyStatusToGenericStatus (status: PolicyStatus): GenericStatus {
  return policyStatusToGenericStatusMap.get(status) ?? GenericStatus.UNKNOWN
}

const genericStatusToApplicationStatusMap = new Map<GenericStatus, ApplicationStatus>([
  [GenericStatus.AUTHORIZATION, 'PENDING_AUTHORISATION'],
  [GenericStatus.CANCELLED, 'ISSUED'],
  [GenericStatus.CANCELLED_WITH_ERROR, 'DECLINED'],
  [GenericStatus.DECLINED, 'DECLINED'],
  [GenericStatus.DRAFT, 'DRAFT'],
  [GenericStatus.EXPIRED, 'TERMINATED'],
  [GenericStatus.IN_FORCE, 'ISSUED'],
  [GenericStatus.NOT_TAKEN_UP_APPLICATION, 'TERMINATED'],
  [GenericStatus.NOT_TAKEN_UP_QUOTE, 'TERMINATED'],
  [GenericStatus.PENDING_APPROVAL, 'PENDING_APPROVAL'],
  [GenericStatus.PENDING_BINDER, 'PENDING_BINDER'],
  [GenericStatus.PENDING_FINALISATION, 'PENDING_FINALISATION'],
  [GenericStatus.PENDING_ISSUE, 'PENDING_ISSUE'],
  [GenericStatus.QUALITY_CONTROL, 'PENDING_QUALITY_CONTROL'],
  [GenericStatus.QUOTED, 'QUOTED'],
  [GenericStatus.QUOTE_LOST, 'DECLINED'],
  [GenericStatus.RENEWAL_DECLINED, 'ISSUED'],
  [GenericStatus.RENEWAL_OFFERED, 'ISSUED'],
  [GenericStatus.READY_FOR_RENEWAL, 'ISSUED'],
  [GenericStatus.RENEWING, 'ISSUED'],
  [GenericStatus.RENEWED, 'ISSUED'],
  [GenericStatus.REQUESTED, 'REQUESTED_TO_BIND'],
  [GenericStatus.SUBMITTED, 'REVIEW'],
  [GenericStatus.TERMINATED, 'TERMINATED'],
  [GenericStatus.UNBOUND, 'PENDING_ISSUE'],
])
/**
 * Converts a {@link GenericStatus} to a {@link ApplicationStatus}.
 */
export function genericStatusToApplicationStatus (status: GenericStatus): ApplicationStatus {
  return genericStatusToApplicationStatusMap.get(status) ?? 'DRAFT'
}

const applicationStatusToGenericStatusMap = new Map<ApplicationStatus, GenericStatus>([
  ['DECLINED', GenericStatus.CANCELLED_WITH_ERROR],
  ['DRAFT', GenericStatus.DRAFT],
  ['ISSUED', GenericStatus.IN_FORCE],
  ['PENDING_APPROVAL', GenericStatus.PENDING_APPROVAL],
  ['PENDING_AUTHORISATION', GenericStatus.AUTHORIZATION],
  ['PENDING_BINDER', GenericStatus.PENDING_BINDER],
  ['PENDING_FINALISATION', GenericStatus.PENDING_FINALISATION],
  ['PENDING_ISSUE', GenericStatus.PENDING_ISSUE],
  ['PENDING_QUALITY_CONTROL', GenericStatus.QUALITY_CONTROL],
  ['QUOTED', GenericStatus.QUOTED],
  ['REQUESTED_TO_BIND', GenericStatus.REQUESTED],
  ['REVIEW', GenericStatus.SUBMITTED],
  ['TERMINATED', GenericStatus.TERMINATED],
])
/**
 * Converts a {@link ApplicationStatus} to a {@link GenericStatus}.
 */
export function applicationStatusToGenericStatus (status: ApplicationStatus, isCancellation: boolean): GenericStatus {
  const mappedStatus = applicationStatusToGenericStatusMap.get(status) ?? GenericStatus.UNKNOWN
  if (mappedStatus === GenericStatus.IN_FORCE && isCancellation) return GenericStatus.CANCELLED
  return mappedStatus
}
