import { Any } from '@policyfly/protobuf/google/protobuf'
import { ScalarType } from '@protobuf-ts/runtime'
import yaml from 'js-yaml'
import kebabCase from 'lodash-es/kebabCase'

import { InspectorRootNodes, noDataTag } from '@/plugins/devtools/shared'
import { base64ToUint8Array, bytesToDataURL } from '@/utils/api'
import { extractAnyWrapper, isArrayFieldInfo } from '@/utils/protobuf'

import type { GrpcLogLevel, Devtools } from '@/plugins/devtools/api'
import type { CustomInspectorStateNode } from '@/plugins/devtools/shared'
import type { IMessageType } from '@protobuf-ts/runtime'
import type { CustomInspectorNode, InspectorNodeTag } from '@vue/devtools-kit'

declare module '@/plugins/devtools/api' {
  interface LogGrpcBaseParams {
    description: string
    logLevel?: GrpcLogLevel
  }
  interface Devtools {
    /**
     * Logs a GRPC message to the PolicyFly inspector, with the ability to visualize the data structure and download the binary.
     */
    logGrpc(data: LogGrpcBaseParams & { messages: [] }): void
    logGrpc<A extends object>(data: LogGrpcBaseParams & { messages: [LogGrpcMessage<A>] }): void
    logGrpc<A extends object, B extends object>(data: LogGrpcBaseParams & { messages: [LogGrpcMessage<A>, LogGrpcMessage<B>] }): void
    logGrpc<A extends object, B extends object, C extends object>(data: LogGrpcBaseParams & { messages: [LogGrpcMessage<A>, LogGrpcMessage<B>, LogGrpcMessage<C>] }): void
    logGrpc<A extends object, B extends object, C extends object, D extends object>(data: LogGrpcBaseParams & { messages: [LogGrpcMessage<A>, LogGrpcMessage<B>, LogGrpcMessage<C>, LogGrpcMessage<D>] }): void
  }
}

type LogGrpcMessage<T extends object = object> = {
  type: IMessageType<T>
  key: string
  message: T | string | undefined
}

/**
 * Deeply parses a message object to make it display in a more user-friendly way.
 *
 * **NOTE: This will mutate the message being passed in. Make sure to clone it first.**
 */
function parseMessage<T extends object> (message: T, type: IMessageType<T>): T {
  for (const field of type.fields) {
    const key = field.jsonName as keyof T
    if (!(key in message)) continue

    const value = message[key]
    // @ts-expect-error(testcase): Allow overwriting values with CustomInspectorStateNode
    const setValue = (v: unknown): void => { message[key] = v }
    switch (field.kind) {
      case 'message':
        if (isArrayFieldInfo(field)) {
          if (Array.isArray(value)) {
            for (const item of value as unknown[]) {
              parseMessage(item, field.T())
            }
          }
        } else {
          parseMessage(value, field.T())
        }
        break
      case 'scalar':
        if (Any.is(value)) {
          const { typeUrl, value: anyValue } = value as Any
          setValue({
            _custom: {
              type: null,
              readonly: true,
              display: extractAnyWrapper(value),
              value,
              actions: [
                {
                  icon: 'visibility',
                  tooltip: 'View Any',
                  action: () => {
                    alert(`Type: ${typeUrl}\nValue: ${anyValue}`)
                  },
                },
              ],
            },
          })
        } else if (field.T === ScalarType.BYTES) {
          const binaryValue = value as Uint8Array
          const isEmpty = binaryValue.length === 0
          setValue({
            _custom: {
              type: null,
              readonly: true,
              display: `Binary Data${isEmpty ? ' (Empty)' : ''}`,
              value,
              actions: isEmpty
                ? []
                : [
                    {
                      icon: 'file_download',
                      tooltip: 'Download Binary',
                      action: () => {
                        const a = document.createElement('a')
                        a.download = `${kebabCase(field.jsonName)}`
                        a.href = bytesToDataURL(binaryValue)
                        a.click()
                      },
                    },
                  ],
            },
          })
        }
        break
      case 'enum': {
        const [enumName, enumType] = field.T()
        const displayName = enumType[value as keyof typeof enumType]
        setValue({
          _custom: {
            type: null,
            readonly: true,
            display: `${displayName} (${value})`,
            value,
            actions: [
              {
                icon: 'visibility',
                tooltip: 'View Enum',
                action: () => {
                  alert(`Value: ${value}\nEnum: ${enumName}\nName: ${displayName}`)
                },
              },
            ],
          },
        })
      }
    }
  }
  // sort keys from a-z, rather than proto id
  return Object.fromEntries(Object.entries(message).sort()) as T
}

function createGrpcNode (description: string, messageDetails: LogGrpcMessage): CustomInspectorStateNode {
  try {
    if (!messageDetails.message) {
      return {
        key: messageDetails.key,
        editable: false,
        value: {
          _custom: {
            type: null,
            readonly: true,
            value: 'No Message',
          },
        },
      }
    }

    const message = typeof messageDetails.message === 'string'
      ? messageDetails.type.fromBinary(base64ToUint8Array(messageDetails.message))
      : messageDetails.type.create(messageDetails.message)

    return {
      key: messageDetails.key,
      editable: false,
      value: {
        _custom: {
          type: null,
          readonly: true,
          value: parseMessage(messageDetails.type.clone(message), messageDetails.type),
          actions: [{
            icon: 'file_download',
            tooltip: 'Download Binary',
            action: () => {
              const a = document.createElement('a')
              a.download = `${kebabCase(description)}-${messageDetails.key}.pb`
              a.href = bytesToDataURL(messageDetails.type.toBinary(message))
              a.click()
            },
          }, {
            icon: 'source',
            tooltip: 'Download YAML',
            action: () => {
              const a = document.createElement('a')
              a.download = `${messageDetails.type.typeName}-${messageDetails.key}.yaml`
              a.href = bytesToDataURL(yaml.dump(messageDetails.type.toJson(message)))
              a.click()
            },
          }],
        },
      },
    }
  } catch (err) {
    const errorMessage = err instanceof Error ? err.message : 'Unknown Error'
    return {
      key: messageDetails.key,
      editable: false,
      value: {
        _custom: {
          type: null,
          readonly: true,
          value: `Error: ${errorMessage}`,
        },
      },
    }
  }
}

interface GrpcDataRecord {
  id: string
  description: string
  label: string
  messages: LogGrpcMessage[]
  nodes: CustomInspectorStateNode[] | null
  logLevel: GrpcLogLevel
}
const grpcData: GrpcDataRecord[] = []
const rootNodeTitle = 'GRPC Data'

export function setupGrpcNode (devtools: Devtools): void {
  devtools.logGrpc = function ({ description, logLevel, messages }: { description: string, logLevel?: GrpcLogLevel, messages: LogGrpcMessage[] }) {
    const devtoolsLogLevel = this.api?.getSettings().grpcLogLevel ?? 'info'
    const grpcLogLevel = logLevel || 'info'
    switch (grpcLogLevel) {
      case 'debug':
        if (devtoolsLogLevel !== 'debug') return
        break
      case 'info':
      default:
        // current highest log level, always allow
    }

    grpcData.push({
      id: this.buildNodeId(InspectorRootNodes.GRPC),
      description,
      label: `${description} [${this.prettyTime()}]`,
      messages,
      nodes: null,
      logLevel: grpcLogLevel,
    })
    while (grpcData.length > this.getNumericSetting('grpcLogLimit', 10)) {
      grpcData.shift()
    }
    devtools.refresh()
  }

  devtools.rootNodeActions.push({
    icon: 'delete',
    tooltip: 'Clear',
    async action (nodeId) {
      switch (devtools.getRootNode(nodeId)) {
        case InspectorRootNodes.GRPC:
          if (await devtools.confirm('Are you sure you want to delete ALL recorded GRPC Data?')) {
            grpcData.splice(0)
            devtools.refresh()
          }
          break
        default:
          devtools.logger.warn('Only GRPC Data can be deleted')
      }
    },
  })

  devtools.rootNodeGetters.push((payload) => {
    if (payload.filter && !rootNodeTitle.toLowerCase().includes(payload.filter.toLowerCase())) {
      return null
    }

    const label = `${rootNodeTitle} (Limit: ${devtools.api!.getSettings().grpcLogLimit})`
    const node: Required<CustomInspectorNode> = {
      id: InspectorRootNodes.GRPC,
      label,
      children: grpcData.map((d) => {
        const tags: InspectorNodeTag[] = []
        switch (d.logLevel) {
          case 'debug': tags.push({ backgroundColor: 0xff8551, label: 'Debug', textColor: 0x000000 }); break
          case 'info': tags.push({ backgroundColor: 0x9bcdd2, label: 'Info', textColor: 0x000000 }); break
        }
        return {
          id: d.id,
          label: d.label,
          tags,
        }
      }),
      tags: [],
      name: '',
      file: '',
    }
    if (!node.children.length) {
      node.tags.push(noDataTag)
    }
    return node
  })

  devtools.stateGetters.push((payload) => {
    if (devtools.getRootNode(payload.nodeId) !== InspectorRootNodes.GRPC) return

    const record = grpcData.find((d) => d.id === payload.nodeId)
    if (record) {
      if (!record.nodes) {
        record.nodes = [
          { key: 'logLevel', value: record.logLevel },
          ...record.messages.map((m) => createGrpcNode(record.description, m)),
        ]
      }
      payload.state = { data: record.nodes }
    }
  })
}
